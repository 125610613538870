import { computed, observable } from 'mobx';
import StoreConstructor from '@/store/core/StoreConstructor';

export enum ABBREVIATIONS {
  'ID' = 'id_info',
  'TIN' = 'tin_info',
  'VAT' = 'vat_info',
  'SF' = 'sf_info',
  'ND' = 'nd_info',
}

export default class Helper extends StoreConstructor {
  @observable defaultAbbreviations = [
    ABBREVIATIONS.ID,
    ABBREVIATIONS.TIN,
    ABBREVIATIONS.VAT,
    ABBREVIATIONS.SF,
    ABBREVIATIONS.ND,
  ];

  @observable
  currentAbbreviations: Partial<typeof this.defaultAbbreviations> = [];

  @computed get abbreviations() {
    if (this.currentAbbreviations.length) return this.currentAbbreviations;
    return this.defaultAbbreviations;
  }
}

import { Invoice } from '@/store/types';
import moment from 'moment/moment';
import {
  computedPaymentStatusLabel,
  computedStatusLabel,
  formatNumber,
  parseArrayLikeString,
} from '@/utils';
import {
  InvoiceDocTypeColor,
  InvoiceDocTypeLabel,
  InvoiceStatusColor,
  PaymentStatusColor,
  PaymentStatusLabel,
} from '@/consts';
import { i18n } from '@/i18n';

export const invoiceToViewMapFunction = (item: Invoice) => {
  const paymentLabelKey = PaymentStatusLabel[item.paymentStatus];
  return {
    ...item,
    invoiceDate: moment(item.date).format('DD.MM.yy'),
    invoiceTurnoverDate: moment(item.invoiceTurnoverDate).format('DD.MM.yy'),
    statusLabel: computedStatusLabel(item.invoiceStatus),
    statusColor: InvoiceStatusColor[item.invoiceStatus],
    docTypeLabel: i18n.t(InvoiceDocTypeLabel[item.documentType]),
    docTypeColor: InvoiceDocTypeColor[item.documentType],
    vatAmount: formatNumber(item.vatAmount),
    costIncludingVAT: formatNumber(item.costIncludingVAT),
    costExcludingVAT: formatNumber(item.costExcludingVAT),
    paymentStatusLabel: i18n.te(PaymentStatusLabel[item.paymentStatus])
      ? i18n.t(PaymentStatusLabel[item.paymentStatus])
      : '—',
    paymentStatusColor: PaymentStatusColor[item.paymentStatus],
    customerName: parseArrayLikeString(item.customerName)?.join(),
    customerTin: parseArrayLikeString(item.customerTin),
    sellerName: parseArrayLikeString(item.sellerName)?.join(),
    salerTin: parseArrayLikeString(item.salerTin),
    invoiceTurnoverSize: formatNumber(item.invoiceTurnoverSize),
    invoiceExcise: formatNumber(item.invoiceExcise),
    contractNum: item?.contractNum ?? '—',
    contractDate: item?.contractDate
      ? moment(item.contractDate).format('DD.MM.yy')
      : '—',
  };
};

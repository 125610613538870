import { AxiosError } from 'axios';
import { AuthData } from '@/api/services/userService/types';

export interface UserState {
  key: AuthData | null;
  info: any | null;
  error: AxiosError | null;
}

export const InvoiceStatuses = [
  'CREATED',
  'DELIVERED',
  'CANCELED',
  'CANCELED_BY_OGD',
  'REVOKED',
  'IMPORTED',
  'DRAFT',
  'FAILED',
  'DELETED',
  'DECLINED',
] as const;
export const InvoiceTypes = ['OUTCOME', 'INCOME'] as const;
export const DocTypes = [
  'ORDINARY_INVOICE',
  'FIXED_INVOICE',
  'ADDITIONAL_INVOICE',
] as const;
export const CounterpartyTypes = ['BUYER', 'SELLER'] as const;
export const PaymentStatusTypes = [
  'PAID',
  'NOT_PAID',
  'PARTIALLY_PAID',
] as const;
export const BankAccountTypes = ['SBER', 'CURRENT', 'ESCROW'] as const;
export const TaxpayerActivities = ['ACTIVE', 'INACTIVE'] as const;
export const TaxpayerEscrows = ['MANDATORY', 'OPTIONAL'] as const;
export const ReceiptStatisticTypes = [
  'OPERATION_BUY',
  'OPERATION_BUY_RETURN',
  'OPERATION_SELL',
  'OPERATION_SELL_RETURN',
] as const;

export type InvoiceType = (typeof InvoiceTypes)[number];
export type DocType = (typeof DocTypes)[number];
export type CounterpartyType = (typeof CounterpartyTypes)[number];
export type InvoiceStatus = (typeof InvoiceStatuses)[number];
export type PaymentStatusType = (typeof PaymentStatusTypes)[number];
export type BankAccountType = (typeof BankAccountTypes)[number];
export type TaxpayerActivityType = (typeof TaxpayerActivities)[number];
export type TaxpayerEscrowType = (typeof TaxpayerEscrows)[number];
export type ReceiptStatisticType = (typeof ReceiptStatisticTypes)[number];

export type Fee = {
  productNumberInSnt: string | null;
  truOriginCode: string | null;
  description: string | null;
  unitNomenclature: string | null;
  quantity: number | null;
  unitPrice: number | null;
  priceWithoutTax: number | null;
  exciseRate: number | null;
  exciseAmount: number | null;
  turnoverSize: number | null;
  ndsRate: number | null;
  ndsAmount: number | null;
  priceWithTax: number | null;
  catalogTruId: string | null;
  productDeclaration: string | null;
  unitCode: string | null;
};

export interface Invoice {
  invoiceNumber: string;
  date: string;
  invoiceTurnoverDate: string;
  invoiceRegNum: string;
  documentId: string;
  correctedDocumentId: string;
  salerTin: string;
  sellerName: string;
  customerTin: string;
  customerName: string;
  costExcludingVAT: number;
  invoiceExcise: number;
  invoiceTurnoverSize: number;
  vatAmount: number;
  costIncludingVAT: number;
  contractNum: string;
  contractDate: string;
  invoiceStatus: InvoiceStatus;
  documentType: DocType;
  paymentStatus: PaymentStatusType;
}

export interface SingleInvoice {
  invoiceNumber: string;
  date: string;
  invoiceTurnoverDate: string;
  invoiceRegNum: string;
  relatedInvoiceRegNum: string | null;
  invoiceTurnoverSize: number | null;
  documentId: string;
  correctedDocumentId: string | null;
  sellerName: string | null;
  sellerId: string | null;
  buyerName: string | null;
  buyerId: string | null;
  currency: string | null;
  costExcludingVAT: number | null;
  invoiceExcise: number | null;
  vatRate: number | null;
  vatAmount: number | null;
  costIncludingVAT: number | null;
  contractNum: string;
  contractDate: string;
  docType: DocType;
  documentStatus: InvoiceStatus;
  paymentStatus: PaymentStatusType;
  items: Fee[];
}

export interface Receipt {
  date: string;
  rn: string;
  fd: string;
  shift_number: string;
  total: string;
  sum_vat: string;
  payment_type: string;
  receipt_type: string;
  sub_total: string;
}

export interface SummaryAnalytics {
  tin: string;
  nameRu: string;
  escrowAccountType: TaxpayerEscrowType;
  errorKkmRisk: boolean;
  saldoNdsRisk: boolean;
  stopOperationRisk: boolean;
  ndsbalance: number;
  accountbalance: number;
  accountValue: string;
  ndstotalout: number;
  ndsinvout: number;
  ndsreceiptout: number;
  ndspaidinvin: number;
  ndsinvin: number;
  ndsnotpaidinvin: number;
  ndsstatwoinvin: number;
  status: TaxpayerActivityType;
  listOfVat: {
    quarter: number;
    year: number;
    ndspaidinvin: number;
    ndsbalance: number;
    ndsreceiptout: number;
  }[];
}

export interface AccountsSummaryAnalytics {
  saldoMoreEscrow: boolean | null;
  finishStopOperation: boolean | null;
  currentBalance: number | null;
  currentSberBalance: number | null;
  vatEscrow: number | null;
  deltaVatEscrow: number | null;
}

export interface BankOperation {
  bank: string;
  bankName: string;
  recipientAccount: string;
  accountType: BankAccountType;
  operationDate: string;
  docKind: string;
  docPurpose: string;
  payerInn: string | null;
  payeeInn: string | null;
  payerName: string | null;
  payeeName: string | null;
  paymentAmount: number;
  withdrawal: number;
  vatAmount: number;
  match: 'MATCHED' | 'NOT_MATCHED';
}

export interface BankAccount {
  bank: string | null;
  accountNumber: string | null;
  accountType: BankAccountType | null;
  accountCurrency: string | null;
  currentBalanceInAccountCurrency: number | null;
  currentBalanceInLocalCurrency: number | null;
}

export interface ReceiptsStatisticType {
  totalReceipts: number;
  revenueTotal: number;
  revenueNonCash: number;
  revenueCash: number;
  acquiringCash: number;
  vatTotal: number;
  vatNonCash: number;
  vatCash: number;
  vatAcquiring: number;
}

export interface ReceiptsStatisticByType {
  totalReceipts: number | null;
  revenueTotal: number | null;
  revenueNonCash: number | null;
  revenueCash: number | null;
  acquiringCash: number | null;
  vatTotal: number | null;
  vatNonCash: number | null;
  vatCash: number | null;
  vatAcquiring: number | null;
  operationType: ReceiptStatisticType;
}

export interface TradePoint {
  name: string;
  address: string;
  receiptsNonCashRevenue: number;
  receiptsNonCashVAT: number;
  acquiringRevenue: number;
  acquiringVAT: number;
  discrepancyRevenue: number;
  discrepancyVAT: number;
}

export interface Partner {
  taxpayerTin: string | null;
  counterpartyInn: string | null;
  counterpartyName: string | null;
  invoiceCount: number | null;
  unPaidInvoiceCount: number | null;
  operationCountWithoutInvoice: number | null;
  totalVAT: number | null;
  totalVATFromPaidInvoices: number | null;
  totalVATFromUnPaidInvoices: number | null;
  totalVATOperationWithoutInvoices: number | null;
  totalCostIncludingVAT: number | null;
  totalCostIncludingVATForUnpaid: number | null;
  totalVATWithoutInvoices: number | null;
}

export type CurrenciesList = {
  kzt: {
    [currency: string]: number;
  };
} & { date: string };

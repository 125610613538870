import { RootStores } from '@/store/core/RootStore';
import { action, computed, observable } from 'mobx';
import moment from 'moment/moment';
import StoreConstructor from '@/store/core/StoreConstructor';
import { quarters } from '@/consts/date';
import { i18n } from '@/i18n';

export default class Period extends StoreConstructor {
  @observable date: {
    quarter: { value: number; label: string } | null;
    year: number;
  } = {
    quarter: null,
    year: new Date().getFullYear(),
  };

  constructor(stores: RootStores) {
    super(stores);

    const currentQuarter = moment(new Date()).quarter();
    const currentLabel = quarters.find(
      (quarter) => quarter.value === currentQuarter,
    )?.label;

    if (currentQuarter && currentLabel) {
      this.date.quarter = {
        value: currentQuarter,
        label: `${currentLabel} ${i18n.t('quarter')}`,
      };
    }
  }

  @computed get dateAsQueryParams() {
    return {
      year: this.date.year,
      ...(this.date.quarter?.value && { quarter: this.date.quarter.value }),
    };
  }

  @computed get dateAsBodyParams() {
    return {
      turnoverYear: this.date.year,
      ...(this.date.quarter?.value && {
        turnoverQuarter: this.date.quarter.value,
      }),
    };
  }

  @computed get range() {
    const date = `${this.date.year}-${this.date.quarter?.value}`;

    return {
      start: this.date.quarter?.value
        ? moment(date).quarter()
        : moment(this.date.year).quarter(),
      end: this.date.quarter?.value
        ? moment(date).endOf('quarter').quarter()
        : moment(this.date.year).quarter(),
    };
  }

  @action.bound setQuarter(quarter: { value: number; label: string } | null) {
    this.date.quarter = quarter || null;
  }

  @action.bound setYear(date: Date) {
    this.date.year = date.getFullYear();
  }
}

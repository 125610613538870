import { createUrl } from '@/utils';

const invoices = createUrl('invoices');

const getInvoices = invoices('');
const getSingleInvoice = invoices('single');

const print = createUrl('print');
const email = createUrl('email');

const sendInvoice = invoices('');

const getLink = print(`getInvoice`);
const sendEmail = (tin: string, subject: string, html: string) =>
  email(`sendHTML?tin=${tin}&subject=${subject}&html=${html}`);

export const invoiceEndpoints = {
  getInvoices,
  getSingleInvoice,
  sendInvoice,
  getLink,
  sendEmail,
};

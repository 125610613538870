import { ApiService } from '@/api/services/core';
import { api } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { AccountsSummaryAnalytics, BankAccount } from '@/store/types';
import { GetBankAccountsParams } from '@/api/services/bankAccountsService/types';
import { Pageable, PageableRequestParams } from '@/types/pagination';
import { GetAccountSummaryParams } from '@/api/services/summaryService/types';

export class BankAccountsService extends ApiService {
  getBankAccounts(params: PageableRequestParams<GetBankAccountsParams>) {
    return api.get<Pageable<BankAccount>>(ENDPOINTS.bankAccounts.bankAccounts, {
      params,
    });
  }

  getAccountsSummary(params: GetAccountSummaryParams) {
    return api.get<AccountsSummaryAnalytics>(
      ENDPOINTS.bankAccounts.commonsAccounts,
      {
        params,
      },
    );
  }
}

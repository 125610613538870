import {
  InvoiceDocTypeColor,
  InvoiceDocTypeLabel,
  InvoiceStatusColor,
  InvoiceStatusLabel,
  PaymentStatusColor,
  PaymentStatusLabel,
  TaxpayerEscrowColor,
  TaxpayerEscrowLabel,
} from '@/consts';
import { i18n } from '@/i18n';
import {
  DocType,
  InvoiceStatus,
  PaymentStatusType,
  TaxpayerEscrowType,
} from '@/store/types';
import { Entries } from '@/types/utility';
import moment from 'moment';
import { Option } from '@/types/option';

export const createUrl = (basePath: string) => (path: string) =>
  `${basePath}${'/' + path}`;

type FormatNumberConfig = {
  incorrectSign?: string;
  fraction?: boolean;
  fractionDigits?: number;
};
const defaultConfig: FormatNumberConfig = {
  incorrectSign: '—',
  fraction: true,
  fractionDigits: 2,
};

export function formatNumber(
  value: number | null | undefined | '',
  options?: FormatNumberConfig,
) {
  const opts = Object.assign(
    { ...defaultConfig },
    options || {},
  ) as Required<FormatNumberConfig>;
  if (value === null || value === undefined || value === '' || isNaN(value))
    return opts.incorrectSign;
  return Number(value.toFixed(2)).toLocaleString('ru', {
    ...(opts.fraction && {
      minimumFractionDigits: opts.fractionDigits,
      maximumFractionDigits: opts.fractionDigits,
    }),
  });
}

export const handleNil = (
  val: any,
  format?: (val: any) => any,
  defaultValue: any = '—',
): any => {
  if (val === null || val === undefined) return defaultValue;
  return format ? format(val) : val;
};

export const formatDate = (val: string, format = 'DD.MM.yy') => {
  return moment(val).format(format);
};

export const computedStatusLabel = (status: InvoiceStatus) => {
  return i18n.te(InvoiceStatusLabel[status])
    ? i18n.t(InvoiceStatusLabel[status])
    : '—';
};

export const computedPaymentStatusLabel = (status: PaymentStatusType) =>
  i18n.te(PaymentStatusLabel[status])
    ? i18n.t(PaymentStatusLabel[status])
    : '—';

export const formatInvoiceDocType = (raw: DocType): Option<DocType> => {
  return {
    label: i18n.t(InvoiceDocTypeLabel[raw]) as string,
    value: raw,
    color: InvoiceDocTypeColor[raw],
  };
};

export const formatInvoiceStatus = (
  raw: InvoiceStatus,
): Option<InvoiceStatus> => {
  return {
    label: i18n.t(InvoiceStatusLabel[raw]) as string,
    value: raw,
    color: InvoiceStatusColor[raw],
  };
};

export const formatTaxpayerEscrow = (
  raw: TaxpayerEscrowType,
): Option<TaxpayerEscrowType> => {
  return {
    label: i18n.t(TaxpayerEscrowLabel[raw]) as string,
    value: raw,
    color: TaxpayerEscrowColor[raw],
  };
};

export const formatInvoicePaymentStatus = (
  raw: PaymentStatusType,
): Option<PaymentStatusType> => {
  return {
    label: i18n.t(PaymentStatusLabel[raw]) as string,
    value: raw,
    color: PaymentStatusColor[raw],
  };
};

export const mapNumericFieldsByFormatNumber = <T>(item: T) => {
  return (Object.entries(item) as Entries<T>).reduce((acc, [key, value]) => {
    acc[key] = typeof value === 'number' ? formatNumber(value) : value ?? '—';
    return acc;
  }, {} as Record<keyof T | string, any>);
};

export const parseArrayLikeString = (string?: string) => {
  if (!string) return ['—'];
  if (string.startsWith('['))
    return string.replace('[', '').replace(']', '').split(', ');
  return [string];
};

import { action, computed, observable } from 'mobx';
import { SingleInvoice } from '@/store/types';
import services from '@/api/services';
import { formatInvoiceFee, formatSingleInvoice } from '@/store/partner/helpers';

export default class partnerInvoiceCard {
  @observable data: SingleInvoice | null = null;
  @observable uuid = '';
  @observable loading = false;

  @action.bound async fetch() {
    try {
      this.loading = true;
      this.data = (
        await services.invoice.getSingleInvoice({
          invoiceId: this.uuid,
        })
      ).data[0];
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  @computed get invoiceView() {
    if (!this.data) return;
    return formatSingleInvoice(this.data);
  }

  @computed get InvoiceFeesView() {
    if (!this.data?.items?.length) return;
    return this.data.items.map(formatInvoiceFee);
  }

  @computed get statements() {
    if (!this.invoiceView) return [];
    return [
      {
        label: 'page_invoices.table.doc_type',
        value: this.invoiceView.docType.label,
      },
      {
        label: 'page_invoices.table.serial_number',
        value: this.invoiceView.invoiceNumber,
      },
      {
        label: 'page_invoices.table.date',
        value: this.invoiceView.date,
      },
      {
        label: 'page_invoices.table.turnover_date',
        value: this.invoiceView.invoiceTurnoverDate,
      },
      {
        label: 'page_invoices.table.id',
        value: this.invoiceView.invoiceRegNum,
      },
      {
        label: 'page_invoices.table.parent_number',
        value: this.invoiceView.relatedInvoiceRegNum,
      },
      {
        label: 'page_invoices.table.contract_num',
        value: this.invoiceView.contractNum,
      },
      {
        label: 'page_invoices.table.contract_date',
        value: this.invoiceView.contractDate,
      },
      {
        label: 'page_invoices.table.seller_name',
        value: this.invoiceView.sellerName,
      },
      {
        label: 'page_invoices.table.seller_tin',
        value: this.invoiceView.sellerId,
      },
      {
        label: 'page_invoices.table.customer_name',
        value: this.invoiceView.buyerName,
      },
      {
        label: 'page_invoices.table.customer_tin',
        value: this.invoiceView.buyerId,
      },
      {
        label: 'page_invoices.table.sum_without_taxes',
        value: this.invoiceView.costExcludingVAT,
      },
      {
        label: 'page_invoices.table.excise',
        value: this.invoiceView.invoiceExcise,
      },
      {
        label: 'page_invoices.table.turnover_size',
        value: this.invoiceView.invoiceTurnoverSize,
      },
      {
        label: 'page_invoices.table.tax_sum',
        value: this.invoiceView.vatAmount,
      },
      {
        label: 'page_invoices.table.sum',
        value: this.invoiceView.costIncludingVAT,
      },
      {
        label: 'page_contractor.invoice.currency',
        value: this.invoiceView.currency,
      },
    ];
  }
}

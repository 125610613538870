import { ApiService } from '@/api/services/core';
import { AuthData, Credentials } from '@/api/services/userService/types';
import { ENDPOINTS } from '@/api/endpoints';
import { api } from '@/api';

export class UserService extends ApiService {
  public login(props: Credentials) {
    return api.post<AuthData>(ENDPOINTS.auth.login, props);
  }

  public refresh(props: { refreshToken: string }) {
    return api.post<AuthData>(ENDPOINTS.auth.refresh, props);
  }
}

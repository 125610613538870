import { action, computed } from 'mobx';
import ListStore from '@/store/core/ListStore';
import { mapNumericFieldsByFormatNumber, formatNumber } from '@/utils';
import { Partner } from '@/store/types';
import services from '@/api/services';
import { DEFAULT_FILTER_VALUE, Filter, FilterState } from '@/store/core/Filter';

type FilterFields = {
  seller: string;
};

export default class PartnerList extends ListStore<Partner> {
  filters = new Filter<FilterState<Partner & FilterFields>>(
    {
      ...DEFAULT_FILTER_VALUE,
      seller: 'false',
    },
    { debounced: ['search'] },
  );

  @action.bound async setType(seller: 'true' | 'false') {
    this.filters.set({ seller, page: 0 });
  }

  @action.bound async fetchData() {
    try {
      this.loading = true;
      const response = await services.partners.getPartners({
        ...this.queryParams(),
        tin: this.stores.user.userTin,
      });

      return {
        items: response.data.content,
        totalItems: 0,
      };
    } catch (e) {
      console.error(e);
      return {
        items: [],
        totalItems: 0,
      };
    } finally {
      this.loading = false;
    }
  }

  @computed get partnersView() {
    if (!this.items.length) return [];
    return this.items.map((p) => ({
      ...mapNumericFieldsByFormatNumber(p),
      invoiceCount: formatNumber(p.invoiceCount, { fractionDigits: 0 }),
    }));
  }
}

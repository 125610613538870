import Vue from 'vue';
import App from './App.vue';
import router from './router';
import dtt from '@dtt/ui-kit';
import { i18n } from '@/i18n';
import '@/assets/styles/index.scss';
import getConfig from '@/plugins/getConfig';
import axios from 'axios';
import { Config } from '@/types/config';

Vue.config.productionTip = false;
Vue.component('default-layout', () => import('@/layouts/default.vue'));
Vue.component('auth-layout', () => import('@/layouts/auth.vue'));
Vue.use(dtt, { i18n: (key: any, value: any) => i18n.t(key, value) });

const runAppWithConfig = async () => {
  const config = (await axios.get<Config>(`/config.json`)).data;
  Vue.use(getConfig, { config });
  new Vue({
    router,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
};

await runAppWithConfig();

import { action, computed, observable } from 'mobx';
import { Invoice, InvoiceType, PaymentStatusType } from '@/store/types';
import ListStore from '@/store/core/ListStore';
import { RootStores } from '@/store/core/RootStore';
import services from '@/api/services';
import { invoiceToViewMapFunction } from '@/utils/invoice';
import { DEFAULT_FILTER_VALUE, Filter, FilterState } from '@/store/core/Filter';

type FilterFields = {
  invoiceType: InvoiceType;
  paymentStatuses: PaymentStatusType[];
};

export default class InvoiceList extends ListStore<Invoice> {
  @observable count = {
    income: 0,
    outcome: 0,
  };
  filters = new Filter<FilterState<Invoice & FilterFields>>(
    {
      ...DEFAULT_FILTER_VALUE,
      paymentStatuses: [],
      invoiceType: '',
      sort: {
        prop: 'date',
        direction: 'desc',
      },
    },
    { debounced: ['search'] },
  );

  constructor(stores: RootStores) {
    super(stores);
  }

  @action.bound async setType(type: InvoiceType) {
    this.filters.set({ invoiceType: type, page: 0 });
  }

  @action.bound async fetchData() {
    try {
      const tinParam =
        this.filters.value.invoiceType === 'OUTCOME'
          ? 'singleSellerTin'
          : 'singleCustomerTin';
      this.loading = true;
      const response = await services.invoice.getInvoices({
        ...this.queryParams(),
        ...this.stores.period.dateAsBodyParams,
        [tinParam]: this.stores.user.userTin,
      });
      return {
        items: response.data.content,
        totalItems: response.data.totalElements,
      };
    } catch (e) {
      console.error(e);
      return {
        items: [],
        totalItems: 0,
      };
    } finally {
      this.loading = false;
    }
  }

  @computed get itemsView() {
    return this.items.map(invoiceToViewMapFunction);
  }
}

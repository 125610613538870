import {
  DocType,
  DocTypes,
  InvoiceStatus,
  InvoiceStatuses,
  PaymentStatusType,
  PaymentStatusTypes,
  TaxpayerEscrows,
  TaxpayerEscrowType,
} from '@/store/types';
import { Option } from '@/types/option';

export const InvoiceStatusLabel: Record<InvoiceStatus, string> =
  InvoiceStatuses.reduce((labels, l) => {
    labels[l] = `terms.invoice.inner_status.${l}`;
    return labels;
  }, {} as Record<InvoiceStatus, string>);

export const InvoiceStatusColor: Record<InvoiceStatus, string> = {
  CREATED: 'neutral',
  DELIVERED: 'success',
  CANCELED: 'neutral',
  CANCELED_BY_OGD: 'neutral',
  REVOKED: 'neutral',
  IMPORTED: 'neutral',
  DRAFT: 'neutral',
  FAILED: 'neutral',
  DELETED: 'neutral',
  DECLINED: 'neutral',
};

export const InvoiceDocTypeLabel: Record<DocType, string> = DocTypes.reduce(
  (labels, l) => {
    labels[l] = `terms.invoice.type.${l}`;
    return labels;
  },
  {} as Record<DocType, string>,
);

export const InvoiceDocTypeColor: Record<DocType, string> = {
  ORDINARY_INVOICE: 'success',
  FIXED_INVOICE: 'neutral',
  ADDITIONAL_INVOICE: 'info',
};

export const PaymentStatusLabel: Record<PaymentStatusType, string> =
  PaymentStatusTypes.reduce((labels, l) => {
    labels[l] = `terms.invoice.payment_status.${l}`;
    return labels;
  }, {} as Record<PaymentStatusType, string>);

export const PaymentStatusColor: Record<PaymentStatusType, string> = {
  PAID: 'success',
  NOT_PAID: 'danger',
  PARTIALLY_PAID: 'accent',
};

export const TaxpayerEscrowColor: Record<TaxpayerEscrowType, string> = {
  MANDATORY: 'danger',
  OPTIONAL: 'success',
};

export const TaxpayerEscrowLabel = TaxpayerEscrows.reduce((labels, l) => {
  labels[l] = `terms.escrow.${l}`;
  return labels;
}, {} as Record<TaxpayerEscrowType, string>);

export const TaxpayerEscrowOptions: Option<TaxpayerEscrowType>[] =
  TaxpayerEscrows.map((value) => ({
    label: TaxpayerEscrowLabel[value],
    value,
  }));

import { computed, makeObservable } from 'mobx';
import StoreConstructor from '@/store/core/StoreConstructor';
import {
  DEFAULT_FILTER_VALUE,
  Filter,
  FilterState,
  Sort,
} from '@/store/core/Filter';
import { RootStores } from '@/store/core/RootStore';
import { PageableRequestParams, SortParams } from '@/types/pagination';

export default abstract class ListStore<T> extends StoreConstructor {
  items: T[] = [];
  totalItems = 0;
  loading = false;
  filters = new Filter<FilterState<any>>(DEFAULT_FILTER_VALUE, {
    debounced: ['search'],
  });
  private filterDisposer?: () => void;

  constructor(stores: RootStores) {
    super(stores);
    makeObservable(this);
  }

  init() {
    this.filterDisposer = this.filters.watch(() => this.fetch());
  }

  destroy() {
    this.filterDisposer && this.filterDisposer();
    this.items = [];
    this.loading = false;
    this.filters.reset();
  }

  queryParams(): PageableRequestParams {
    const { sort, size, page, ...queryParams } = this.filters.value;
    const pageParams = { pageNum: page, pageSize: size };
    const periodParams = this.stores.period.dateAsQueryParams;

    const filters = (
      Object.keys(queryParams) as (keyof Partial<FilterState>)[]
    ).reduce((acc, key) => {
      if (this.filters.value[key]) acc[key] = this.filters.value[key];
      return acc;
    }, {} as Partial<FilterState>);

    const params = Object.assign(filters, pageParams, periodParams);
    const sorts = {
      ...(sort.prop && {
        columnSort: sort.prop,
        order: sort.direction?.toUpperCase(),
      }),
    } as SortParams;

    return Object.assign(params, sorts);
  }

  @computed get maxPage() {
    return Math.max(
      0,
      Math.ceil(this.totalItems / this.filters.value.size) - 1,
    );
  }

  async setPageSize(size: number | null) {
    size && this.filters.set({ size, page: 0 });
  }

  async setSort(sort: Sort) {
    this.filters.set({ sort, page: 0 });
  }

  async refresh() {
    await this.fetch();
  }

  async fetch(params?: any) {
    this.loading = true;
    try {
      const { items, totalItems } = await this.fetchData(params);
      this.items = items || [];
      this.totalItems = totalItems || 0;
    } catch (e) {
      console.error(e);
      this.items = [];
      this.filters.value.totalItems = 0;
    } finally {
      this.loading = false;
    }
  }

  fetchData(args: any): Promise<{ items: any[]; totalItems: number }> {
    throw new Error('Need to override the Fetch method');
  }
}

import { Config } from '@/types/config';
import _Vue from 'vue';

export default {
  install: async (Vue: typeof _Vue, { config }: { config: Config }) => {
    Vue.prototype.$config = config;
    Vue.prototype.$role = config.role;
    Vue.prototype.$isTaxpayer = config.role === 'taxpayer';
    Vue.prototype.$isBank = config.role === 'bank';
  },
};

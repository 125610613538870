import { UserService } from '@/api/services/userService';
import { InvoiceService } from '@/api/services/invoiceService';
import { SummaryService } from '@/api/services/summaryService';
import { BankOperationsService } from '@/api/services/bankOperationsService';
import { BankAccountsService } from '@/api/services/bankAccountsService';
import { ReceiptsService } from '@/api/services/receiptsService';
import { PartnersService } from '@/api/services/partnersService';

export default {
  user: new UserService(),
  invoice: new InvoiceService(),
  summary: new SummaryService(),
  bankOperations: new BankOperationsService(),
  bankAccounts: new BankAccountsService(),
  receipts: new ReceiptsService(),
  partners: new PartnersService(),
};

import { createUrl } from '@/utils';

const bankAccountsUrl = createUrl('statements');

const bankAccounts = bankAccountsUrl('accounts');
const commonsAccounts = bankAccountsUrl('user/statement/common');

export const bankAccountsEndpoints = {
  bankAccounts,
  commonsAccounts,
};

import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { AuthData } from '@/api/services/userService/types';
import stores from '@/store/core/RootStore';
import router from '@/router';
import services from '@/api/services';

type ExtendedAxiosRequestConfig =
  | (AxiosRequestConfig & {
      isRetry: boolean;
    })
  | undefined;

const token =
  'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJraGFyYXRpYW4uYXJ0aHVyQHVuaW9uc21hcnR0ZWNoLmNvbSIsInR5cGUiOiJDVVNUT01FUiIsImlkIjoyMDk0NywibHBJZCI6MjA5NTAsInNjb3BlcyI6WyJST0xFX0NVU1RPTUVSIl0sImlzcyI6ImNmcnMiLCJpYXQiOjE3MDE4NjEzMTQsImV4cCI6MjAxNzQ4MDUxNH0.DuH88kUG9M2Xf4aA-Xi2_wMBIvLDtjY4anG6JmVxYxkwcOgKRQTWHTt-YiTzQQUyaFn4SdAvo0EuHY0al7pkCQ';
const isProduction = process.env.NODE_ENV === 'production';
const API_URL = isProduction
  ? 'https://elastic-ext.dev-24x7.com/'
  : 'http://10.36.99.196:9200';

const elasticApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    ...(isProduction && {
      'access-token': token,
    }),
  },
  transformResponse: (response) => {
    const data = JSON.parse(response);
    const items = data?.hits?.hits || [data];
    const aggregations = data?.aggregations;
    if (items?.length || (aggregations && Object.keys(aggregations).length)) {
      return {
        data: items.map((item: any) => item._source),
        totalItems: data?.hits?.total?.value || 0,
        aggregations: aggregations || [],
      };
    }
    return null;
  },
});

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://ui-api.dev-24x7.com/ui/api/v1'
      : '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

const REQUEST_INTERCEPTORS = [
  (config: InternalAxiosRequestConfig) => {
    if (config.headers) {
      // config.headers['Authorization'] = getBearer(stores.user.token);
    }
    return config;
  },
  (error: any) => error,
];

const RESPONSE_INTERCEPTORS = [
  (response: AxiosResponse) => response,
  async (error: any) => {
    console.log(error);
    const config = error.config as ExtendedAxiosRequestConfig;
    if (error?.response?.status === 401 && config && !config.isRetry) {
      config.isRetry = true;
      try {
        // isRefreshing = isRefreshing ? isRefreshing : refresh();
        const data = await isRefreshing;
        isRefreshing = null;
        // stores.user.setKey(data);
        return api.request(config);
      } catch (error) {
        console.error(error);
        // stores.user.removeKey();
        await router.push('/auth');
      }
    }
    return Promise.reject(error);
  },
];

export const getBearer = (token?: string) => `Bearer ${token || ''}`;

const refresh = async () => {
  // const refreshToken: string = stores.user.refreshToken || '';
  // const response = await services.user.refresh({ refreshToken });
  // return response.data;
};

let isRefreshing: Promise<AuthData> | null = null;

export { api, elasticApi };

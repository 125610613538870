import { ApiService } from '@/api/services/core';
import { api } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { GetInvoicesParams } from '@/api/services/invoiceService/types';
import {
  Pageable,
  RequestBody,
  PageableRequestParams,
} from '@/types/pagination';
import { extractBodyAndQueryFromParams } from '@/utils/params';
import { Invoice, SingleInvoice } from '@/store/types';

export class InvoiceService extends ApiService {
  getInvoices(props: PageableRequestParams<GetInvoicesParams>) {
    const { body, params } = extractBodyAndQueryFromParams(props);
    return api.post<Pageable<Invoice>>(ENDPOINTS.invoice.getInvoices, body, {
      params,
    });
  }

  getSingleInvoice(body: PageableRequestParams<RequestBody>) {
    return api.post<SingleInvoice[]>(ENDPOINTS.invoice.getSingleInvoice, body);
  }
}

import { action, computed, observable } from 'mobx';
import StoreConstructor from '@/store/core/StoreConstructor';
import {
  formatNumber,
  formatTaxpayerEscrow,
  mapNumericFieldsByFormatNumber,
} from '@/utils';
import services from '@/api/services';
import { SummaryAnalytics } from '@/store/types';

export default class Summary extends StoreConstructor {
  @observable loading = false;
  @observable.deep summary: SummaryAnalytics | null = null;

  @computed get summaryView():
    | Partial<Record<keyof SummaryAnalytics | string, any>>
    | undefined {
    if (!this.summary) return;
    return Object.assign(mapNumericFieldsByFormatNumber(this.summary), {
      escrowAccountType: formatTaxpayerEscrow(this.summary.escrowAccountType),
      accountbalanceDelta:
        this.summary?.accountbalance - this.summary?.ndsbalance,
    });
  }

  @computed get warnings() {
    if (!this.summaryView) return [];
    const items: { label: string; status?: any }[] = [];
    if (this.summaryView.escrowAccountType.value) {
      items.push({
        label: 'эскроу-счёт',
        status: this.summaryView.escrowAccountType,
      });
    }
    if (this.summaryView.errorKkmRisk)
      items.push({ label: 'Нарушение применения ККМ' });
    if (
      this.summaryView.escrowAccountType.value === 'MANDATORY' &&
      this.summaryView.saldoNdsRisk
    )
      items.push({ label: 'Сальдо НДС больше остатка на эскроу-счёте' });
    if (this.summaryView.stopOperationRisk)
      items.push({
        label: 'Принято решение о приостановке расходных операций',
      });
    return items;
  }

  @action.bound async fetch() {
    try {
      this.loading = true;
      const quarter = this.stores.period.date.quarter?.value;
      const response = await services.summary.getSummary({
        year: this.stores.period.date.year || new Date().getFullYear(),
        tin: this.stores.user.userTin,
        ...(quarter && { quarter }),
      });

      this.summary = response.data;
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}

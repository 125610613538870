import { ApiService } from '@/api/services/core';
import { api } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { GetSummaryParams } from '@/api/services/summaryService/types';
import { SummaryAnalytics } from '@/store/types';

export class SummaryService extends ApiService {
  getSummary(params: GetSummaryParams) {
    return api.post<SummaryAnalytics>(ENDPOINTS.summary.commons, null, {
      params,
    });
  }
}

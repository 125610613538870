import { PageableRequestParams } from '@/types/pagination';

export const extractBodyAndQueryFromParams = <T extends Record<string, any>>(
  props: PageableRequestParams<T>,
) => {
  const {
    id,
    status,
    key,
    singleSellerTin,
    singleCustomerTin,
    tin,
    turnoverQuarter,
    turnoverYear,
    paymentStatuses,
    ...params
  } = props;
  const body = {
    id,
    status,
    key,
    singleSellerTin,
    singleCustomerTin,
    tin,
    turnoverQuarter,
    turnoverYear,
    ...(paymentStatuses?.length && { paymentStatuses }),
  };

  return { body, params };
};

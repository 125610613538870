<template>
  <div id="app">
    <dtt-loader v-if="loading" overlay />
    <component :is="layout" v-else>
      <router-view />
    </component>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import stores from '@/store/core/RootStore';

const DEFAULT_LAYOUT = 'default';

@Component
export default class App extends Vue {
  user = stores.user;
  loading = true;

  get layout() {
    return (this.$route.meta?.layout || DEFAULT_LAYOUT) + '-layout';
  }

  async loadModules() {
    const modules = await Promise.all(
      this.$config.modules.map(
        (moduleName) => import(`@/router/routes/${moduleName}`),
      ),
    );
    let routes = modules
      .map((module) => module.default)
      .flat() as RouteConfig[];

    const mapRoutesAsLead = (routes: RouteConfig[]): RouteConfig[] => {
      return routes.map((route) => ({
        ...route,
        path: '/' + route.path,
      }));
    };

    if (['taxpayer'].includes(this.$role)) {
      routes = mapRoutesAsLead(routes);
    }
    routes.forEach((route) => {
      this.$router.addRoute(route);
    });
  }

  @Watch('$route.query.tin', { immediate: true })
  onChangeTin() {
    const tin = this.$route.query.tin as string;
    tin && this.user.setTin(tin);
  }

  async beforeMount() {
    await this.loadModules();
    await this.$nextTick();
    this.loading = false;
  }
}
</script>

<style lang="scss"></style>

import { ApiService } from '@/api/services/core';
import { api } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { BankOperation } from '@/store/types';
import { GetBankOperationsParams } from '@/api/services/bankOperationsService/types';
import { Pageable, PageableRequestParams } from '@/types/pagination';

export class BankOperationsService extends ApiService {
  getBankOperations(params: PageableRequestParams<GetBankOperationsParams>) {
    return api.get<Pageable<BankOperation>>(
      ENDPOINTS.bankOperations.bankOperations,
      {
        params,
      },
    );
  }
}

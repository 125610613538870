import { authEndpoints as auth } from '@/api/endpoints/auth';
import { invoiceEndpoints as invoice } from '@/api/endpoints/invoice';
import { commonsEndpoints as summary } from '@/api/endpoints/summary';
import { bankOperationsEndpoints as bankOperations } from '@/api/endpoints/bankOperations';
import { bankAccountsEndpoints as bankAccounts } from '@/api/endpoints/bankAccounts';
import { receiptsEndpoints as receipts } from '@/api/endpoints/receipts';
import { partnersEndpoints as partners } from '@/api/endpoints/partners';

export const ENDPOINTS = {
  auth,
  invoice,
  summary,
  bankOperations,
  bankAccounts,
  receipts,
  partners,
};

import { ApiService } from '@/api/services/core';
import { api } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import {
  ReceiptsStatisticType,
  ReceiptsStatisticByType,
  TradePoint,
} from '@/store/types';
import { PageableRequestParams } from '@/types/pagination';
import {
  GetReceiptsParams,
  GetTradePointsParams,
} from '@/api/services/receiptsService/types';

export class ReceiptsService extends ApiService {
  getStatistic(params: PageableRequestParams<GetReceiptsParams>) {
    return api.get<ReceiptsStatisticType>(ENDPOINTS.receipts.statistic, {
      params,
    });
  }

  getStatisticByType(params: GetReceiptsParams) {
    return api.get<ReceiptsStatisticByType[]>(
      ENDPOINTS.receipts.statisticByType,
      { params },
    );
  }

  getTradePoints(params: PageableRequestParams<GetTradePointsParams>) {
    return api.post<TradePoint[]>(ENDPOINTS.receipts.tradePoints, null, {
      params,
    });
  }
}

import { makeAutoObservable, entries } from 'mobx';
import _ from 'lodash';

export interface FilterConfig<T> {
  guard?: (value: T) => boolean;
  debounced?: (keyof T)[];
  wait?: number;
}

export type Sort<T = Record<string, any>> = {
  prop: keyof T | '';
  direction: 'asc' | 'desc' | null;
};

export type Filters<T> = {
  [Key in keyof T]?: T[Key] | '';
};

export type FilterState<T = Record<string, any>> = {
  search: string;
  page: number;
  size: number;
  sort: Sort<T>;
} & Filters<T>;

export const DEFAULT_FILTER_VALUE = {
  search: '',
  page: 0,
  size: 20,
  totalItems: 0,
  sort: {
    prop: '',
    direction: 'asc',
  },
} as const;

export class Filter<T> {
  value: T;

  private readonly defaultValue: T;
  private watchers: ((value: T) => any)[] = [];
  private readonly guard?: (value: T) => boolean;
  private readonly debounced: (keyof T)[];
  private readonly wait: number;
  private debounceDisposer?: () => any;

  constructor(value: T, config: FilterConfig<T> = {}) {
    makeAutoObservable(this);
    this.guard = config.guard;
    this.debounced = config.debounced ?? [];
    this.wait = config.wait ?? 1000;
    this.defaultValue = { ...value };
    this.value = { ...value };
  }

  get isValid() {
    return this.guard ? this.guard(this.value) : true;
  }

  set(value: Partial<T>) {
    this.setSilent(value);
    if (!this.debounced.length) {
      this.triggerWatchers();
      return;
    }
    const keys = Object.keys(value) as (keyof T)[];
    if (_.intersection(this.debounced, keys).length) {
      this.debounceDisposer && this.debounceDisposer();
      const fn = _.debounce(() => this.triggerWatchers(), this.wait);
      this.debounceDisposer = fn.cancel;
      fn();
    } else {
      this.triggerWatchers();
    }
  }

  setSilent(value: Partial<T>) {
    Object.entries(value).forEach(([key, value]) => {
      if (value instanceof Array) {
        this.value[key as keyof T] = value.concat() as any;
      } else this.value[key as keyof T] = value as any;
    });
  }

  watch(cb: (value: T) => any): () => void {
    const index = this.watchers.push(cb) - 1;
    return () => {
      this.watchers.splice(index, 1);
    };
  }

  reset() {
    entries(this.defaultValue).forEach(([key, value]) => {
      this.value[key as keyof T] = value;
    });
  }

  private triggerWatchers() {
    if (!this.isValid) return;
    for (const cb of this.watchers) {
      cb(this.value);
    }
  }
}

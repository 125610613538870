import { formatNumber, mapNumericFieldsByFormatNumber } from '@/utils';
import services from '@/api/services';
import {
  AccountsSummaryAnalytics,
  BankAccount,
  BankOperation,
} from '@/store/types';
import ListStore from '@/store/core/ListStore';
import { DEFAULT_FILTER_VALUE, Filter, FilterState } from '@/store/core/Filter';
import Currency from '@/store/currency';
import { i18n } from '@/i18n';

export default class BankAccounts extends ListStore<BankAccount> {
  commons: AccountsSummaryAnalytics | null = null;
  filters = new Filter<FilterState<BankOperation>>({
    ...DEFAULT_FILTER_VALUE,
  });
  currency = new Currency();

  get bankAccountsView(): Record<keyof BankAccount | string, any>[] {
    if (!this.items.length) return [];
    return this.items.map((a) =>
      Object.assign(mapNumericFieldsByFormatNumber(a), {
        currentBalanceInLocalCurrency: formatNumber(
          this.currency.exchangeKZT(
            a.currentBalanceInAccountCurrency || 0,
            a.accountCurrency,
          ),
        ),
        accountType: i18n.t(`page_operations.accountTypes.${a.accountType}`),
      }),
    );
  }

  get banksAccountsCommonsView() {
    if (!this.commons) return;

    const reducerByLocalCurrency = (acc: number, item: BankAccount) =>
      (acc += this.currency.exchangeKZT(
        item.currentBalanceInAccountCurrency || 0,
        item.accountCurrency,
      ));

    const currentBalance = this.items
      .filter((i) => i.accountType === 'CURRENT')
      .reduce(reducerByLocalCurrency, 0);

    const currentSberBalance = this.items
      .filter((i) => i.accountType === 'SBER')
      .reduce(reducerByLocalCurrency, 0);

    return {
      ...this.commons,
      currentBalance: formatNumber(currentBalance),
      currentSberBalance: formatNumber(currentSberBalance, {
        incorrectSign: '0',
      }),
      vatEscrow: formatNumber(this.commons.vatEscrow),
    };
  }

  get warnings() {
    if (!this.banksAccountsCommonsView) return [];
    const items: { label: string; status?: any }[] = [];
    if (
      this.banksAccountsCommonsView.saldoMoreEscrow &&
      this.stores.summary.summaryView?.escrowAccountType.value === 'MANDATORY'
    )
      items.push({ label: 'Сальдо НДС больше остатка на эскроу-счёте' });
    if (this.banksAccountsCommonsView?.finishStopOperation)
      items.push({
        label: 'Принято решение о приостановке расходных операций',
      });
    return items;
  }

  async fetchData() {
    try {
      this.loading = true;
      const response = await services.bankAccounts.getBankAccounts({
        ...this.queryParams(),
        tin: this.stores.user.userTin,
      });

      return {
        items: response.data.content,
        totalItems: response.data.totalElements,
      };
    } catch (e) {
      console.error(e);
      return {
        items: [],
        totalItems: 0,
      };
    } finally {
      this.loading = false;
    }
  }

  async fetchSummary() {
    try {
      this.loading = true;
      const response = await services.bankAccounts.getAccountsSummary({
        ...this.stores.period.dateAsQueryParams,
        user: this.stores.user.userTin,
      });
      this.commons = response.data;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}

import { ApiService } from '@/api/services/core';
import { api } from '@/api';
import { ENDPOINTS } from '@/api/endpoints';
import { GetPartnersParams } from '@/api/services/partnersService/types';
import { Pageable, PageableRequestParams } from '@/types/pagination';
import { Partner } from '@/store/types';
import { extractBodyAndQueryFromParams } from '@/utils/params';

export class PartnersService extends ApiService {
  public getPartners(props: PageableRequestParams<GetPartnersParams>) {
    const { body, params } = extractBodyAndQueryFromParams(props);
    return api.post<Pageable<Partner>>(ENDPOINTS.partners.taxpayers, body, {
      params,
    });
  }
}

import Helper from '@/store/helper';
import Period from '@/store/period';
import InvoiceList from '@/store/invoice/invoiceList';
import User from '@/store/user';
import PartnerList from '@/store/partner/partnerList';
import PartnerInvoiceCard from '@/store/partner/partnerCard';
import PartnerInvoices from '@/store/partner/partnerInvoices';
import Summary from '@/store/summary';
import BankOperations from '@/store/bankOperations';
import BankAccounts from '@/store/bankAccounts';
import ReceiptsStatistic from '@/store/receipts/receiptsStatistic';
import TradePoints from '@/store/receipts/tradePoints';

export class RootStores {
  user = new User(this);
  invoiceList = new InvoiceList(this);
  partnersList = new PartnerList(this);
  partnerInvoiceCard = new PartnerInvoiceCard();
  partnerInvoices = new PartnerInvoices(this);
  period = new Period(this);
  helper = new Helper(this);
  summary = new Summary(this);
  bankOperations = new BankOperations(this);
  bankAccounts = new BankAccounts(this);
  receipts = new ReceiptsStatistic(this);
  tradePoints = new TradePoints(this);
}

const stores = new RootStores();

export default stores;

import { action } from 'mobx';
import services from '@/api/services';
import ListStore from '@/store/core/ListStore';

export default class TradePoints extends ListStore<TradePoints> {
  @action.bound async fetchData() {
    try {
      this.loading = true;
      const response = await services.receipts.getTradePoints({
        ...this.queryParams,
      });

      return {
        items: response.data,
        totalItems: 0,
      };
    } catch (e) {
      console.error(e);
      return {
        items: [],
        totalItems: 0,
      };
    } finally {
      this.loading = false;
    }
  }
}

import { CurrenciesList } from '@/store/types';
import { api } from '@/api';

export default class Currency {
  list: CurrenciesList | null = null;
  urls: string[] = [
    'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/kzt.json',
    'https://latest.currency-api.pages.dev/v1/currencies/kzt.json',
  ];

  constructor() {
    this.fetchCurrency();
  }

  async fetchCurrency() {
    for await (const url of this.urls) {
      try {
        const result = await api.get<CurrenciesList>(url);
        this.list = result.data;
        if (result) return result;
      } catch (e) {
        console.error(e);
      }
    }
  }

  getRate(c?: string | null) {
    if (!c) return 1;
    return (
      this.list?.kzt?.[c?.toLowerCase() as keyof CurrenciesList['kzt']] || 1
    );
  }

  exchangeKZT(value: number, currency?: string | null) {
    if (!currency) return value;
    return value / this.getRate(currency);
  }
}

import { action, computed } from 'mobx';
import { mapNumericFieldsByFormatNumber } from '@/utils';
import services from '@/api/services';
import { BankOperation } from '@/store/types';
import ListStore from '@/store/core/ListStore';
import { DEFAULT_FILTER_VALUE, Filter, FilterState } from '@/store/core/Filter';
import { i18n } from '@/i18n';

export default class BankOperations extends ListStore<BankOperation> {
  filters = new Filter<FilterState<BankOperation>>(
    {
      ...DEFAULT_FILTER_VALUE,
      search: '',
      sort: {
        prop: 'operationDate',
        direction: 'desc',
      },
    },
    { debounced: ['search'] },
  );

  @computed get bankOperationsView(): Record<
    keyof BankOperation | string,
    any
  >[] {
    return this.items.map((o) => ({
      ...mapNumericFieldsByFormatNumber(o),
      counterpartyLabel:
        `${o.payeeInn || o.payeeInn || ''} ${
          o.payeeName || o.payerName || ''
        }`.trim() || '—',
      docKind: i18n.t(`page_operations.purposeTypes.${o.docKind}`),
      accountType: i18n.t(`page_operations.accountTypes.${o.accountType}`),
    }));
  }

  @action.bound async fetchData() {
    try {
      this.loading = true;
      const response = await services.bankOperations.getBankOperations({
        ...this.queryParams(),
        user: this.stores.user.userTin,
      });
      return {
        items: response.data.content,
        totalItems: response.data.totalElements,
      };
    } catch (e) {
      console.error(e);
      return {
        items: [],
        totalItems: 0,
      };
    } finally {
      this.loading = false;
    }
  }
}

import { action, computed, observable } from 'mobx';
import { mapNumericFieldsByFormatNumber, formatNumber } from '@/utils';
import services from '@/api/services';
import { ReceiptsStatisticType, ReceiptsStatisticByType } from '@/store/types';
import StoreConstructor from '@/store/core/StoreConstructor';
import { i18n } from '@/i18n';

export default class ReceiptsStatistic extends StoreConstructor {
  @observable receiptsStats: ReceiptsStatisticType | null = null;
  @observable receiptsStatsByType: ReceiptsStatisticByType[] | null = null;
  @observable loading = false;
  @computed get receiptsStatsView() {
    if (!this.receiptsStats) return null;
    const receiptsStatsView: Record<keyof ReceiptsStatisticType | string, any> =
      {
        ...mapNumericFieldsByFormatNumber(this.receiptsStats),
        totalReceipts: formatNumber(this.receiptsStats.totalReceipts, {
          fractionDigits: 0,
        }),
      };

    receiptsStatsView.revenueNonCashDelta =
      this.receiptsStats.revenueNonCash - this.receiptsStats.acquiringCash;

    receiptsStatsView.vatNonCashDelta =
      this.receiptsStats.vatNonCash - this.receiptsStats.vatAcquiring;

    return receiptsStatsView;
  }

  @computed get receiptsStatsByTypeView() {
    if (!this.receiptsStatsByType) return null;
    return this.receiptsStatsByType.map((s) => ({
      ...mapNumericFieldsByFormatNumber<ReceiptsStatisticByType>(s),
      countOperations: formatNumber(s.totalReceipts, { fractionDigits: 0 }),
      operationType: i18n.t(
        `page_receipts.receipt.operation_type.${s.operationType}`,
      ),
    }));
  }

  @action.bound async fetchStatistic() {
    try {
      const response = await services.receipts.getStatistic({
        ...this.stores.period.dateAsQueryParams,
        tin: this.stores.user.userTin,
      });

      this.receiptsStats = response.data;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound async fetchStatisticByType() {
    try {
      const response = await services.receipts.getStatisticByType({
        ...this.stores.period.dateAsQueryParams,
        tin: this.stores.user.userTin,
      });

      this.receiptsStatsByType = response.data;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound async fetchTradePoints() {
    try {
      const response = await services.receipts.getTradePoints({});
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound async fetchAllStatistic() {
    try {
      this.loading = true;
      await Promise.allSettled([
        this.fetchStatistic(),
        this.fetchStatisticByType(),
      ]);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}

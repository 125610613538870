import { action, computed, observable } from 'mobx';
import {
  Invoice,
  InvoiceType,
  PaymentStatusType,
  SummaryAnalytics,
} from '@/store/types';
import ListStore from '@/store/core/ListStore';
import services from '@/api/services';
import { invoiceToViewMapFunction } from '@/utils/invoice';
import { DEFAULT_FILTER_VALUE, Filter, FilterState } from '@/store/core/Filter';

type FilterFields = {
  invoiceType: InvoiceType;
  paymentStatuses: PaymentStatusType[];
};

export default class InvoiceList extends ListStore<Invoice> {
  @observable taxNumber = '';
  @observable taxpayer: SummaryAnalytics | null = null;
  filters = new Filter<FilterState<Invoice & FilterFields>>(
    {
      ...DEFAULT_FILTER_VALUE,
      invoiceType: '',
      paymentStatuses: [],
    },
    {
      debounced: ['search'],
    },
  );

  @action.bound async fetchPartner() {
    try {
      const response = await services.summary.getSummary({
        tin: this.taxNumber,
        ...this.stores.period.dateAsQueryParams,
      });
      this.taxpayer = response.data;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound async setType(type: InvoiceType) {
    this.filters.set({ invoiceType: type, page: 0 });
  }

  @action.bound async fetchData() {
    try {
      this.loading = true;
      const tinParams =
        this.filters.value.invoiceType === 'OUTCOME'
          ? {
              singleSellerTin: this.taxNumber,
              singleCustomerTin: this.stores.user.userTin,
            }
          : {
              singleCustomerTin: this.taxNumber,
              singleSellerTin: this.stores.user.userTin,
            };
      const response = await services.invoice.getInvoices({
        ...this.queryParams(),
        ...this.stores.period.dateAsBodyParams,
        ...tinParams,
      });
      return {
        items: response.data.content,
        totalItems: response.data.totalElements,
      };
    } catch (e) {
      console.error(e);
      return {
        items: [],
        totalItems: 0,
      };
    } finally {
      this.loading = false;
    }
  }

  @computed get itemsView() {
    return this.items.map(invoiceToViewMapFunction);
  }
}

import { createUrl } from '@/utils';

const taxpayersUrl = createUrl('taxpayers');

const statistic = taxpayersUrl('no/receipts');
const statisticByType = taxpayersUrl('no/statistic/receipts');
const tradePoints = taxpayersUrl('statistic/tradePoints');

export const receiptsEndpoints = {
  statistic,
  statisticByType,
  tradePoints,
};

import Vue from 'vue';
import VueRouter, { NavigationGuard } from 'vue-router';
import routes from '@/router/routes';
import Component from 'vue-class-component';

Vue.use(VueRouter);
Component.registerHooks(['beforeRouteEnter']);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const permanentQueryTinParam: NavigationGuard = (to, from, next) => {
  if (!to.query.tin && from.query.tin) {
    next({
      ...(to.name ? { name: to.name } : { path: to.path }),
      query: { ...to.query, tin: from.query.tin },
      params: to.params,
    });
  } else {
    next();
  }
};

router.beforeEach(permanentQueryTinParam);

export default router;
